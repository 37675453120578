import { $, $$ } from './dom'
import { hideEmailStubs } from './email'

const setTrianglePosition = (
  $control: HTMLButtonElement,
  $tooltip: Element,
) => {
  const $triangle = $<HTMLSpanElement>($tooltip, 'span.\\@tooltip-triangle')
  if (!$triangle) return

  const { offsetLeft, offsetWidth } = $control
  $triangle.style.left = `${offsetLeft + offsetWidth / 2 - 30}px`
}

const toggleTooltipVisibility = (
  $control: HTMLButtonElement,
  $tooltip: Element,
) => {
  if ($control.getAttribute('aria-expanded') === 'false') {
    $tooltip.removeAttribute('hidden')
    $control.setAttribute('aria-expanded', 'true')
  } else {
    $tooltip.setAttribute('hidden', 'true')
    $control.setAttribute('aria-expanded', 'false')
  }
}

export const tooltip = () => {
  $$<HTMLButtonElement>('button[aria-expanded][aria-controls]').forEach(
    ($control) => {
      $control.addEventListener('click', (event) => {
        event.preventDefault()

        const $tooltip = $(`#${$control.getAttribute('aria-controls')}`)
        if (!$tooltip) return

        setTrianglePosition($control, $tooltip)
        hideEmailStubs($tooltip)
        toggleTooltipVisibility($control, $tooltip)
      })
    },
  )
}
