import { $$ } from './dom'

export const hideEmailStubs = ($tooltip: Element) => {
  $$<HTMLSpanElement>($tooltip, '[data-email] span').forEach(($span) => {
    $span.style.display = 'none'
  })
}

export const email = () => {
  const domain = 'leonardodino.com'
  $$<HTMLAnchorElement>('a[data-email="local-part"]').forEach(($anchor) => {
    $anchor.addEventListener('click', (event) => {
      event.preventDefault()
      window.location.assign(`mailto:${$anchor.innerText}${domain}`)
    })
  })
}
