function $<T extends Element>(parent: Element, selector: string): T | null
function $<T extends Element>(selector: string, _?: never): T | null
function $(p1: Element | string, p2: unknown) {
  if (typeof p1 === 'string') return document.querySelector(p1)
  if (typeof p2 === 'string') return p1.querySelector(p2)
  return null
}

function $$<T extends Element>(parent: Element, selector: string): NodeListOf<T>
function $$<T extends Element>(selector: string, _?: never): NodeListOf<T>
function $$(p1: Element | string, p2: unknown) {
  if (typeof p1 === 'string') return document.querySelectorAll(p1)
  if (typeof p2 === 'string') return p1.querySelectorAll(p2)
  return []
}

export { $, $$ }
